import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import styled from 'styled-components'
import {ArrowRight, ArrowLeft} from '@styled-icons/bootstrap'

const NextPrev = styled.div`
  background: #000;
  color: white; 
  .icon {
    display: inline-block;
      height: 32px;
    svg {
      height: 32px;
    }
  }
`
const NextPrevArticle = (props) => {

  return (
    <NextPrev className="mt-5 mb-2 pt-4 pb-4 pr-4 pl-4">
      <h1 className="h3 font-weight-normal text-warning text-right">{props.title}</h1>
      <p className="text-right">{props.subtitle}</p>
        <Row className="no-gutters">
          <Col>
            {props.prev ? <Link className="icon" to={props.prev}><ArrowLeft /></Link> : null}
          </Col>
          <Col className="text-right">
            <Link className="icon" to={props.next}><ArrowRight /></Link>
          </Col>
        </Row>
        
    </NextPrev>
  )
}

export default NextPrevArticle