import React, { useRef }  from "react"
import IframeResizer from 'iframe-resizer-react'
import { InView } from 'react-intersection-observer'

const HypeIframe = ({ animationName }) => {

  const ref = useRef(null);

  const trigPlay = (data) => {
    ref.current.sendMessage(data)
  };

  /*
    if (message === "Continue Hype") {
      hypeDocument.continueTimelineNamed('Main Timeline', hypeDocument.kDirectionForward, true);
    } else if (message === "Continue Hype Always") {
      hypeDocument.continueTimelineNamed('Main Timeline', hypeDocument.kDirectionForward, false);
    } else if (message === "Play Hype") {
      hypeDocument.startTimelineNamed('Main Timeline', hypeDocument.kDirectionForward)
  */

  return (

  <InView as="div" onChange={(inView, entry) => trigPlay('Continue Hype Always')}>
    <IframeResizer
    frameBorder='0'
    forwardRef={ref}
    onMessage={trigPlay}
    className='mt-3 mb-3'
    src={`/hype/${animationName}/index.html`}
    style={{ width: '1px', minWidth: '100%'}}
    />
  </InView>
)};

export default HypeIframe