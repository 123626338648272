import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { Container, Row, Col } from "react-bootstrap"
import { Layout } from "../components/Layout";
import styled from 'styled-components'
import Sidenav from "../components/Sidenav"
import Hype from "../components/Hype"
import HypeIframe from "../components/HypeIframe"
import Seo from "react-seo-component"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import PropTypes from "prop-types"
import Boxout from "../components/Boxout"
import NextPrevArticle from "../components/NextPrevArticle"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const shortcodes = { 
  Hype, 
  HypeIframe,
  Container, 
  Row, 
  Col,
  getImage,
  GatsbyImage,
  Boxout,
  NextPrevArticle,
  blockquote: (props) => <blockquote {...props} className="blockquote font-weight-light" /> 
}

const Section = styled.section`
    /* margin-top: 0; */
    section {
        padding: 12rem 0rem 0;
        h2 {
            width: 60%;
            padding-top: 2rem;
        }
    }
`;

const ArticleContent = styled.div`
    margin-bottom: 6rem;
`

const ArticlCover = styled(BackgroundImage)`
    min-height: 77vh;
`

export default function PostPage({ data }) {
  const {
    body,
    slug,
    excerpt,
    embeddedImagesRemote,
    frontmatter: { title, date, embeddedImagesLocal }
  } = data.mdx;
  const image = getImage(data.mdx.frontmatter.banner)
  const bgImage = convertToBgImage(image)
  const {
    title: siteTitle,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    socialimage,
    authorName,
  } = useSiteMetadata();
  return (
    <Layout>
      <Seo
        title={title}
        titleTemplate={siteTitle}
        description={excerpt}
        pathname={`${siteUrl}${slug}`}
        article={true}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        image={socialimage}
        author={authorName}
        publishedDate={date}
        modifiedDate={new Date(Date.now()).toISOString()}
      />
      <Section>
        <ArticlCover
          Tag="section"
          className=""
          {...bgImage}
          preserveStackingContext
        >
        </ArticlCover>
        {/* <GatsbyImage image={image} alt={title} layout="fullWidth" /> */}
        <Container>
            <Row>
                <Col className="d-none d-sm-none d-md-none d-lg-block d-xl-block" lg={3}>
                    <Sidenav />
                </Col>
                <Col className="ribbon bg-white mt-n10" xs={12} lg={9}>
                    <div className="bg-white pt-2">
                        <h2 className="h1 font-weight-light mt-4 mb-4">{title}</h2>
                    </div>
                    <ArticleContent>
                        <MDXProvider components={shortcodes}>
                          <MDXRenderer
                            remoteImages={embeddedImagesRemote}
                            localImages={embeddedImagesLocal}
                          >{body}</MDXRenderer>
                        </MDXProvider>
                    </ArticleContent>
                </Col>
            </Row>
        </Container>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query POST_BY_SLUG($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      slug
      body
      excerpt
      frontmatter {
        title
        banner {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
`;

PostPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
      })
    }).isRequired,
  }).isRequired,
}