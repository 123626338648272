import React from "react"
import styled from 'styled-components'

const BreakoutContainer = styled.div`
  
  position:relative;
  background: #212529;
  background: linear-gradient(180deg, #111111 0%, #212529 100%);
  color: white;
  width:100vw;
  left: -30px;
  // My breakout container magic ^_^
  @media (min-width: 576px) {
    left: calc((-1 * (30px) - ((100vw - 540px)/2)));
  } 
  @media (min-width: 768px) {
    left: calc((-1 * (30px) - ((100vw - 720px)/2)));
  } 
  @media (min-width: 992px) {
    left: calc((-1 * ((960px / 4) + 30px) - ((100vw - 960px)/2)));
  } 
  @media (min-width: 1200px) {
    left: calc((-1 * ((1140px / 4) + 30px) - ((100vw - 1140px)/2)));
  } 
`

const Boxout = (props) => {
  return (
    <BreakoutContainer className="mt-4 mb-4 pt-5 pb-5">
      {/* <Container> */}
        {props.children}
      {/* </Container> */}
    </BreakoutContainer>
  )
}

export default Boxout