import React, { useEffect } from "react"


const Hype = ({ animationName }) => {
  const isBrowser = typeof window !== undefined

  useEffect(() => {

    const container = document.getElementById(
      `${animationName}_hype_container`
    )

    if (isBrowser) {
      const script = document.createElement("script")

      script.src = `/hype/${animationName}/${animationName}.hyperesources/${animationName}_hype_generated_script.js`
      script.async = true

      if (container !== null) {
        container.appendChild(script)
      }
    }
  }, [isBrowser,animationName ])

  return (
    <div
      id={`${animationName}_hype_container`}
      className="HYPE_document"
      style={{
        position: `relative`,
        marginBottom: "1rem",
      }}
    />
  )
}

export default Hype